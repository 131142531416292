import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '@app/components/layout';
import { Section } from '@app/components/Section';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import { Fade } from '@app/components/animations/Fade';

const BlogPage = ({ data }: any) => {
  const first = data.allMdx.nodes.length > 0 ? data.allMdx.nodes.find((x: any) => x.frontmatter.is_feature) : null;
  return (
    <Layout
      title="projects"
      className="project-list-page"
      navActiveElementIds={['project-list']}
      navItems={[
        {
          name: 'homepage',
          path: '/'
        },
      ]}
    >
      <Section
        id='introduction'
        className="introduction"
        disableAnimate
        heading='Projects'
        contentClassName='d-flex align-items-center'
        visualScroll
        scrollTo="project-list"
      >
        {first && <div className='row'>
          <div className='col-md-6 d-md-flex align-items-md-center'>
            <Link to={first.frontmatter.path || `/projects/${first.slug}`}>
              <GatsbyImage
                image={getImage(first.frontmatter.thumb_image)}
              />
            </Link>
          </div>
          <div className='col-md-6'>
            <div className='project-item--name'>
              <Link
                to={first.frontmatter.path || `/projects/${first.slug}`}
                className='text-white'
              >
              {first.frontmatter.title}
              </Link>
            </div>
            <p className='post-detail-description text-white'>
              {first.frontmatter.description}
            </p>
            <p className='post-detail-type mb-0 text-white'>
              {first.frontmatter.type}
            </p>
          </div>
        </div>}
      </Section>
      <Section
        id='project-list'
        className='first'
        disableAnimate
      >
        <div className='project-list'>
          {
            data.allMdx.nodes.filter((x: any) => x.id !== first.id).map((item: any, index: number) => {
              const image = getImage(item.frontmatter.thumb_image)
              return (
                <Fade key={item.id} as='article' bottom>
                  <div className='project-item'>
                    <Link
                      to={item.frontmatter.path || `/projects/${item.slug}`}
                    >
                    <GatsbyImage
                      image={image}
                    />
                    </Link>
                    <div className='project-item--name'>
                      <Link
                        to={item.frontmatter.path || `/projects/${item.slug}`}
                      >
                        {item.frontmatter.title}
                      </Link>
                    </div>
                    <p className='project-item--type mb-0'>
                      {item.frontmatter.type}
                    </p>
                  </div>
                </Fade>
              )
            })
          }
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC},filter: {frontmatter: {hide: {eq: false}}}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          type
          description
          path
          is_feature
          thumb_image {
            childImageSharp {
              gatsbyImageData(width: 634, height: 428, quality: 100)
            }
          }
        }
        id
        slug
      }
    }
  }
`

export default BlogPage